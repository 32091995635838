import React from 'react';

import Segment from '../../components/segment';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import HeroAnimationCenter from '../../components/sections/heroAnimationCenter/heroAnimationCenter';

import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';

import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';

import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';

const Bronze = () => {
  const lang = 'se_sv';
  const heroId = 'kom-igang';

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      dataTheme="white"
      HeaderFloatShow
      headerWitdh=""
      headerStyle="flex flex--center text-center container container--lg"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />

      <Seo
        title="Uppnå dina egna mål med Joint Academy"
        description="Friskare leder och mindre smärta på 5 minuter om dagen"
        language="sv"
        ogImage="https://assets.jointacademy.com/images/joint_academy_activities_rectangle_bronze.jpg"
      />
      <HeroAnimationCenter
        dataTheme="light"
        title="Behandla artros och ledsmärta direkt i mobilen"
        titleClassName="bold"
        text="Ladda ner Joint Academy till din mobil idag."
        tagHeadline="Få ett sms med länk för att ladda ner appen"
        elementId={heroId}
        showSocial
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="kom igång idag"
        ctaDataGoal="CTA Clicked"
        ctaHref={`#${heroId}`}
      />
      <BgCardsTextST dataTheme="light" lang={lang} />

      <SocialProofFeedST lang={lang} sectionClassName="margin-bottom--lg" />
    </Layout>
  );
};

export default Bronze;
